import { FeaturesLoader, intl, styles, ui } from '@beelday/common'
import { PreloadedFonts } from 'common/presentation/preloaded-fonts'
import { rootStore } from 'common/redux'
import messages from 'intl/messages'
import LoadAppModules from 'load-app-modules'
import 'normalize.css/normalize.css'
import { FunctionComponent } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { RoutedApp } from 'routed-app'
import './common/presentation/global.css'
import { init, ErrorBoundary } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { Config } from 'common/config'
import { AudioContextProvider } from './features/user-engagement/audio-context-provider'
import { Global } from '@emotion/react'

if (Config.PROD) {
	console.log('Enable Sentry logging')
	init({
		dsn: 'https://1bc4511a162a45868901fc17b193bf8f@o1155969.ingest.sentry.io/6288638',
		integrations: [new BrowserTracing()],
		tracesSampleRate: 1.0,
	})
}

const App: FunctionComponent = () => {
	return (
		<ErrorBoundary
			fallback={({ error }) => <ui.Error message={error?.toString()} />}
		>
			<Global styles={styles.global} />
			<PreloadedFonts />
			<LoadAppModules />
			<intl.IntlProvider messages={messages}>
				<ui.ConfirmProvider>
					<Provider store={rootStore}>
						<AudioContextProvider>
							<FeaturesLoader>
								<RoutedApp />
							</FeaturesLoader>
						</AudioContextProvider>
					</Provider>
				</ui.ConfirmProvider>
			</intl.IntlProvider>
		</ErrorBoundary>
	)
}

ReactDOM.render(<App />, document.getElementById('root'))
