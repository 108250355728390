import { FC } from 'react'
import styled from '@emotion/styled'
import { colors, intl, ui } from '@beelday/common'
import { Config } from 'common/config'
import KnowledgeCheckAnswers from './knowledge-check-answers'
import {
	KnowledgeCheckOptionID,
	KnowledgeCheckQuestion,
	KnowledgeCheckQuestionID,
} from '../knowledge-check'

const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative;
`

const ContainerInner = styled.div`
	display: flex;
	flex-flow: row wrap;
	flex-direction: column;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	padding: 20px 0;
`

const ContainerContent = styled.div`
	flex: 1;
	margin-left: 40px;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 15px 10px 0 10px;
	width: calc(100% - 80px);
`

const ContainerFooter = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: auto;
	padding: 20px 50px;
`

const QuestionSubTitle = styled.div`
	color: ${colors.heather};
	font-size: 14px;
	line-height: 17px;
	margin-bottom: 8px;
`

const QuestionTitle = styled.div`
	font-size: 20px;
	font-weight: 500;
	line-height: 24px;
`

const QuestionImage = styled.div`
	padding: 0 60px;
	margin: 10px auto 0;

	img {
		border-radius: 11px;
		max-width: 100%;
	}
`

type Props = {
	question: KnowledgeCheckQuestion
	answers: KnowledgeCheckOptionID[]
	isLast: boolean
	isFirst: boolean
	onAnswer: (
		questionId: KnowledgeCheckQuestionID,
		answers: KnowledgeCheckOptionID[]
	) => void
	onPrev: () => void
	onNext: () => void
	onFinish: (
		questionId: KnowledgeCheckQuestionID,
		answers: KnowledgeCheckOptionID[]
	) => void
}

const KnowledgeCheckQuestion: FC<Props> = ({
	question,
	isLast,
	isFirst,
	answers,
	onAnswer,
	onNext,
	onPrev,
	onFinish,
}) => {
	const checked = answers

	return (
		<Container>
			<ContainerInner>
				<ContainerContent>
					<QuestionSubTitle>
						<intl.Translate>
							knowledge_check.question.select_answer
						</intl.Translate>
					</QuestionSubTitle>
					<QuestionTitle>{question.question}</QuestionTitle>
					{question.image && question.image.fileId && (
						<QuestionImage>
							<img
								src={`${Config.sessionPlanningURL}/files/${question.image?.fileId}`}
								alt="image"
							/>
						</QuestionImage>
					)}
					<KnowledgeCheckAnswers
						options={question.options}
						checked={checked}
						onChange={newChecked => {
							onAnswer(question.id, newChecked)
						}}
					/>
				</ContainerContent>
				<ContainerFooter>
					{isFirst || (
						<ui.ButtonMinor onClick={onPrev}>
							<intl.Translate>knowledge_check.question.prev</intl.Translate>
						</ui.ButtonMinor>
					)}

					{isLast ? (
						<ui.ButtonMinor
							onClick={() => onFinish(question.id, checked)}
							style={{ marginLeft: 'auto' }}
						>
							<intl.Translate>knowledge_check.question.finish</intl.Translate>
						</ui.ButtonMinor>
					) : (
						<ui.ButtonMinor onClick={onNext} style={{ marginLeft: 'auto' }}>
							<intl.Translate>knowledge_check.question.next</intl.Translate>
						</ui.ButtonMinor>
					)}
				</ContainerFooter>
			</ContainerInner>
		</Container>
	)
}

export default KnowledgeCheckQuestion
