import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Colors } from 'common/presentation/colors'
import { transformedClippingBorderRadius } from 'common/presentation/cross-browser-css'
import { VideoStreamAvatarOverlay } from 'common/presentation/video-stream-avatar-overlay'
import { Color } from 'common/types'
import { Property } from 'csstype'
import { MuteIcon } from 'features/microphone'
import { FunctionComponent, useMemo } from 'react'
import { colorToHex } from 'room/common/presentation/colorsInHex'
import { VideoStream } from 'video-conference-media/presentation/video-stream'
import { SceneVideoUser } from '../model/types'
import { Username } from './username'

const DisplayContainer = styled.div`
	bottom: 4px;
	display: flex;
	left: 4px;
	max-width: calc(100% - 8px);
	position: absolute;
`

export type StreamEmphases = {
	presenterColor?: Property.Color
}

type Props = StreamEmphases & {
	sceneVideoUser: SceneVideoUser
	className?: string
	hideName?: boolean
	scale?: number
	fillColor?: Color
	fontColor?: string
}

const intro = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const boxShadow = (props: Props) => {
	if (props.presenterColor && props.sceneVideoUser.presenter) {
		return css`
			box-shadow: 0 0 6px 6px ${props.presenterColor};
		`
	}
	if (props.sceneVideoUser.spotlight) {
		return css`
			box-shadow: 0 0 6px 6px ${Colors.brightCyan};
		`
	}
	return undefined
}

export const SceneVideoUserStream: FunctionComponent<Props> = props => {
	const transform = props.scale ? `scale(${props.scale})` : undefined
	const { stream, muted, videoMuted, isSelfView, userName, userId } =
		props.sceneVideoUser
	const hasMediaStreams =
		!!stream?.getVideoTracks().length && !!stream?.getAudioTracks().length

	const displayStream = useMemo(() => {
		if (stream?.screenShareTrackId) {
			return new MediaStream(
				stream?.getTracks().filter(t => t.id !== stream.screenShareTrackId) ||
					[]
			)
		} else if (isSelfView) {
			return new MediaStream(stream?.getVideoTracks() || [])
		} else {
			return stream
		}
	}, [isSelfView, stream])

	if (stream?.getVideoTracks().length === 0 && stream?.screenShareTrackId) {
		return (
			<div
				css={css`
					${boxShadow(props)};
					${transformedClippingBorderRadius('5px', transform)};
					height: 100%;
					width: 100%;
					transition: box-shadow 100ms ease-in-out;
					background-color: ${props.fillColor
						? colorToHex[props.fillColor]
						: Colors.indigoBlue};
					animation: ${intro} 300ms ease-in-out 1;
					position: relative;
				`}
				className={props.className}
			>
				{videoMuted && <VideoStreamAvatarOverlay userId={userId} />}
				<DisplayContainer>
					{muted ? <MuteIcon /> : null}
					<Username {...props}>{userName}</Username>
				</DisplayContainer>
			</div>
		)
	}

	return displayStream && hasMediaStreams ? (
		<div
			css={css`
				${boxShadow(props)};
				${transformedClippingBorderRadius('5px', transform)};
				height: 100%;
				width: 100%;
				transition: box-shadow 100ms ease-in-out;
				background-color: ${props.fillColor
					? colorToHex[props.fillColor]
					: Colors.indigoBlue};
				animation: ${intro} 300ms ease-in-out 1;
				position: relative;
			`}
			className={props.className}
		>
			<VideoStream
				userId={props.sceneVideoUser.userId}
				css={css`
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					object-fit: cover;
					${isSelfView ? 'transform: rotateY(180deg);' : ''}
				`}
			/>

			{videoMuted && <VideoStreamAvatarOverlay userId={userId} />}

			<DisplayContainer>
				{muted ? <MuteIcon /> : null}
				<Username {...props}>{userName}</Username>
			</DisplayContainer>
		</div>
	) : (
		<div
			css={css`
				${boxShadow(props)};
				${transformedClippingBorderRadius('5px', transform)};
				height: 100%;
				width: 100%;
				transition: box-shadow 100ms ease-in-out;
				background-color: ${props.fillColor
					? colorToHex[props.fillColor]
					: Colors.indigoBlue};
				animation: ${intro} 300ms ease-in-out 1;
				position: relative;
			`}
			className={props.className}
		>
			{videoMuted && <VideoStreamAvatarOverlay userId={userId} />}
			<DisplayContainer>
				{muted ? <MuteIcon /> : null}
				<Username {...props}>{userName}</Username>
			</DisplayContainer>
		</div>
	)
}
