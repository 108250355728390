import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { findUserInteractionScheme } from './model/workflow'
import { TrainingInteractionScheme } from 'interaction-scheme/training-interaction-scheme'
import { setJoinedInteractionScheme, setUser } from './redux'
import { EventsSourcePath } from 'common/types'
import { RootState } from 'common/redux'
import { setEventsSourcePath } from 'event-source-manager/redux'
import RefreshToRejoinWorkflow from './refresh-to-rejoin-workflow'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { security, ui, intl } from '@beelday/common'
import { useHistory } from 'react-router-dom'
import { RoomTransferCountdown } from 'interaction-scheme/room-transfer-countdown'
import EndTraining from './presentation/end-training'

export const JoinableWorkflow: FC = () => {
	const history = useHistory()
	const workflowId = useSelector(
		(state: RootState) => state.workflow.workflowId
	)
	const authenticatedUser = security.useAuthenticatedUser()
	const joinedInteractionScheme = useSelector(
		(state: RootState) => state.workflow.joinedInteractionScheme
	)
	const latestWorkflowEvent = useSelector(
		(state: RootState) => state.eventSource.latestWorkflowEvent
	)
	const isEndTraining = useSelector(
		(state: RootState) => state.workflow.endTraining
	)

	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()

	useEffect(() => {
		if (!workflowId) {
			return
		}
		beeldayClient
			.joinWorkflow({
				workflowId,
			})
			.catch(({ status }) => {
				if (status === 404) {
					history.replace('/404')
				}
			})
	}, [beeldayClient, dispatch, history, workflowId])

	useEffect(() => {
		if (latestWorkflowEvent && workflowId && authenticatedUser) {
			const userInteractionScheme = findUserInteractionScheme(
				workflowId,
				authenticatedUser.id,
				latestWorkflowEvent
			)
			if (
				userInteractionScheme &&
				userInteractionScheme.interactionSchemeId !==
					joinedInteractionScheme?.interactionSchemeId
			) {
				dispatch(setJoinedInteractionScheme(userInteractionScheme))
				dispatch(setEventsSourcePath(EventsSourcePath.interactionScheme))
				dispatch(setUser(userInteractionScheme.user))
			}
		}
	}, [
		dispatch,
		joinedInteractionScheme,
		latestWorkflowEvent,
		authenticatedUser,
		workflowId,
	])

	return isEndTraining ? (
		<EndTraining />
	) : joinedInteractionScheme ? (
		<>
			<TrainingInteractionScheme />
			<RefreshToRejoinWorkflow />
			<RoomTransferCountdown />
		</>
	) : (
		<ui.FlexColumnCenter
			style={{
				rowGap: '40px',
				height: '100vh',
				justifyContent: 'center',
			}}
		>
			<ui.Spinner size={96} />
			<strong style={{ fontSize: '24px' }}>
				<intl.Translate>transfer_to_session</intl.Translate>
			</strong>
		</ui.FlexColumnCenter>
	)
}
