import { colors, images, intl, ui, UUID } from '@beelday/common'
import { useToggle } from '@beelday/common/src/hooks'
import { css, keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ToggleItemProps } from 'room/common/presentation/menu'
import {
	MiscSelectedSelectedItem,
	setMiscPanelOpen,
	setMiscPanelSelectedItem,
} from 'room/common/redux'

const Notification = styled.div`
	align-items: center;
	background-color: ${colors.pinkRed};
	border-radius: 50%;
	border: 2px solid ${colors.white};
	color: white;
	display: flex;
	font-size: 11px;
	font-weight: 500;
	height: 20px;
	justify-content: center;
	position: absolute;
	right: 10px;
	top: 7px;
	width: 20px;
	pointer-events: none;

	transition: 0.2s ease-out;
	z-index: 1;
`

type Entities =
	| 'POST'
	| 'COMMENT'
	| 'KNOWLEDGE_CHECK'
	| 'SESSION_RATING'
	| 'SINGLE_CHOICE_POLLING'

export type Extracted = Record<
	Entities,
	{ postsIds: Array<string>; count: number }
>

type Props = {
	trainingId: UUID
	open: boolean
}

export const FeedNotifications: React.FC<Props> = ({
	trainingId,
	open,
	children,
}) => {
	const feedNotificationsRef = useRef<HTMLElement>(null)
	const [selectedPostId, setSelectedPostId] = useState<string>('none')
	const element = feedNotificationsRef.current
	const [notifications, setNotifications] = useState<Extracted>()

	const selectPostIdAsync = useCallback(
		async (postId: string) => setSelectedPostId(postId),
		[]
	)

	useEffect(() => {
		if (open === false) {
			setSelectedPostId('none')
		}
	}, [open])

	useEffect(() => {
		if (element) {
			const feedChangeListener = (customEvent: Event) => {
				const feedStatus = (customEvent as CustomEvent).detail
				setNotifications(feedStatus)
			}

			element.addEventListener('feed-change', feedChangeListener)
			return () => {
				element.removeEventListener('feed-change', feedChangeListener)
			}
		}
	}, [element])

	const openProp = open ? 'true' : 'false'

	return (
		<div
			style={{
				position: 'relative',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<beelday-training-feed-notifications
				trainingId={trainingId}
				open={openProp}
				postid={selectedPostId}
				ref={feedNotificationsRef}
			/>

			{notifications ? (
				<Notifications
					notifications={notifications}
					selectPostId={selectPostIdAsync}
				>
					{children}
				</Notifications>
			) : (
				<>{children}</>
			)}
		</div>
	)
}

const CircleContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	width: 46px;
	height: 46px;
	transition: 0.3s ease;
	perspective: 1000px;

	${ui.desktop1440} {
		width: 56px;
		height: 56px;
	}
`

const Icon = styled.div`
	position: absolute;
	width: 40px;
	height: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: white;
	font-weight: bold;
	transition: 0.6s ease;
	opacity: 0;

	&.show {
		transition: 0.6s ease;
		opacity: 1;
	}
`

type NotificationsProps = {
	notifications: Extracted
	selectPostId: (postId: string) => Promise<void>
}

const Notifications: React.FC<NotificationsProps> = ({
	notifications,
	selectPostId,
	children,
}) => {
	const { value: isHovered, setOff, setOn } = useToggle()
	const dispatch = useDispatch()

	const radius = ui.responsive.useIsDesktop1440() ? 56 : 46

	const calculatePosition = (index: number, total: number) => {
		const iconSize = 40

		if (total === 1) {
			return { x: 0, y: radius * 1.5 }
		}

		const maxAngle = Math.PI
		const totalAngle = Math.min(total * (iconSize / radius), maxAngle)

		const angleStep = totalAngle / (total - 1)

		const centerOffset = (total - 1) / 2

		const angle = (index - centerOffset) * angleStep

		const x = radius * 1.5 * Math.sin(angle)
		const y = radius * 1.5 * Math.cos(angle)

		return { x, y }
	}

	const entries = useMemo(
		() => Object.entries(notifications),
		[notifications]
	).filter(([, val]) => val.count > 0)

	const numberOfChanges = useMemo(
		() =>
			Object.values(notifications || []).reduce((acc, curr) => {
				return acc + curr.count
			}, 0),
		[notifications]
	)

	const isActiveRating = useMemo(
		() => notifications.SESSION_RATING.count > 0,
		[notifications.SESSION_RATING]
	)

	return (
		<>
			<ui.Flex
				css={css`
					:hover {
						.feed-notifications-circle {
							width: ${radius * 2}px;
							height: ${radius * 2}px;
						}
					}
				`}
				style={{ position: 'relative', justifyContent: 'center' }}
				onMouseEnter={setOn}
				onMouseLeave={setOff}
			>
				<CircleContainer
					className={`feed-notifications-circle`}
					style={{
						transform: isHovered ? `translateY(-${radius * 1.5}px)` : '',
						width: `${isHovered ? radius * 3.5 : radius}px`,
						height: `${isHovered ? radius * 2.5 : radius}px`,
					}}
				>
					{entries.map(([entity, notification], index) => {
						const { x, y } = calculatePosition(index, entries.length)
						return (
							<Icon
								className={isHovered ? 'show' : ''}
								key={index}
								style={{
									transform: isHovered
										? `translate(${x}px, ${-y}px)`
										: `translate(0, 0)`,
								}}
								onClick={() => {
									selectPostId(
										notification.postsIds[notification.count - 1]
									).then(() => {
										dispatch(
											setMiscPanelSelectedItem(
												MiscSelectedSelectedItem.Boardlet
											)
										)
										dispatch(setMiscPanelOpen(true))
									})
								}}
							>
								<div
									css={css`
										height: 100%;
										width: 100%;
										max-height: 40px;
										max-width: 40px;
										border-radius: 20%;

										img {
											max-width: 100%;
											max-height: 100%;
											border-radius: 20%;
										}
									`}
								>
									{entity === 'KNOWLEDGE_CHECK' ? (
										<ui.Tooltip
											overlay={
												<intl.Translate>
													feed.notifications.entity.KNOWLEDGE_CHECK
												</intl.Translate>
											}
											placement="top"
										>
											<img src={images.knowledgeCheckTemplateIcon} />
										</ui.Tooltip>
									) : entity === 'SESSION_RATING' ? (
										<ui.Tooltip
											overlay={
												<intl.Translate>
													feed.notifications.entity.SESSION_RATING
												</intl.Translate>
											}
											placement="top"
										>
											<img src={images.ratingTemplateIcon} />
										</ui.Tooltip>
									) : entity === 'SINGLE_CHOICE_POLL' ? (
										<ui.Tooltip
											overlay={
												<intl.Translate>
													feed.notifications.entity.SINGLE_CHOICE_POLL
												</intl.Translate>
											}
											placement="top"
										>
											<img src={images.singleChoicePolling} />
										</ui.Tooltip>
									) : entity === 'POST' ? (
										<ui.Tooltip
											overlay={
												<intl.Translate>
													feed.notifications.entity.POST
												</intl.Translate>
											}
											placement="top"
										>
											<img src={images.post} />
										</ui.Tooltip>
									) : (
										<ui.Tooltip
											overlay={
												<intl.Translate>
													feed.notifications.entity.COMMENT
												</intl.Translate>
											}
											placement="top"
										>
											<img src={images.comment} />
										</ui.Tooltip>
									)}
									<Notification style={{ top: '-10px', right: '-10px' }}>
										{notification.count}
									</Notification>
								</div>
							</Icon>
						)
					})}
				</CircleContainer>

				<div
					css={[
						css`
							transition: 0.2s ease-out;
						`,
						isActiveRating
							? css`
									animation: ${shake} 1s ease-in-out infinite;
							  `
							: isHovered
							? css`
									transform: translateY(-4px);
							  `
							: '',
					]}
				>
					{numberOfChanges > 0 && (
						<Notification className="feed-notification-dot">
							{numberOfChanges}
						</Notification>
					)}
					{React.isValidElement<ToggleItemProps>(children) &&
						React.cloneElement(children, {
							className: classNameResolver(isActiveRating, isHovered),
							css: css`
								&:hover:enabled {
									transform: none;

									svg {
										fill: inherit;
									}
								}

								.button-circle.rating-active {
									background-color: #ffcf40;
									svg {
										fill: ${colors.white};
									}
								}
								.hover.rating-active {
									color: ${colors.white};
									svg {
										fill: ${colors.white};
									}
								}
							`,
						})}
				</div>
			</ui.Flex>
		</>
	)
}

const classNameResolver = (
	isActiveRating: boolean,
	isHovered: boolean
): string => {
	if (isHovered) {
		return isActiveRating ? 'hover rating-active' : 'hover'
	}
	return isActiveRating ? 'rating-active' : ''
}

const shake = keyframes`
  0% { transform: translateX(0); }
  10% { transform: translateX(0); }
  20% { transform: translateX(4px); }
  30% { transform: translateX(-4px); }
  40% { transform: translateX(4px); }
  50% { transform: translateX(-4px); }
  60% { transform: translateX(4px); }
  70% { transform: translateX(-4px); }
  80% { transform: translateX(4px); }
  90% { transform: translateX(0); }
  100% { transform: translateX(0); }
`
