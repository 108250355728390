/* eslint-disable @typescript-eslint/no-explicit-any */
import { events, logger, security } from '@beelday/common'
import { Config } from 'common/config'
import { RootState } from 'common/redux'
import {
	EventsSourcePath,
	InteractionSchemeAddress,
	QuizQuestionAnswered,
	QuizQuestionAsked,
	QuizQuestionCompleted,
	RoomAddress,
	RoomType,
	UserId,
	WorkflowId,
} from 'common/types'
import BeeldayClient, {
	EtherPadClosedEvent,
	EtherPadOpenedEvent,
	ExcalidrawClosedEvent,
	ExcalidrawGroupSwitchedEvent,
	ExcalidrawOpenedEvent,
	GroupPropertiesChangedEvent,
	GroupsInitialStateEvents,
	GroupStateChangedEvent,
	HandPositionChangedEvent,
	PlayersChangedEvent,
	QuizConcludedEvent,
	RoomStateChangedEvent,
	TrainerBecameAvailableEvent,
	TrainerBecameUnavailableEvent,
	TrainerIsJoiningGroupEvent,
	TrainerStatusChangedEvent,
} from 'connectivity/beelday-client'
import { useAuthenticatedBeeldayClient } from 'connectivity/beelday-hooks'
import { toGroupSuggestions } from 'connectivity/model-mappers'
import { setISRoomTransferWithCountdown } from 'interaction-scheme/redux'
import { FunctionComponent, useCallback, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { setRoomUsers, setTrainingRoomPhase } from 'room/common/redux'
import {
	changeGroupName,
	handleEtherPadClosed,
	handleEtherPadOpened,
	handleExcalidrawClosed,
	handleExcalidrawOpened,
	handleGroupStateChangedEvent,
	handleHandPositionChangedEvent,
	handleTrainerBecameAvailableEvent,
	handleTrainerBecameUnavailableEvent,
	handleTrainerIsJoiningGroupEvent,
	handleTrainerStatusChangedEvent,
} from 'room/group-room/redux'
import { handlePublicChatRoomChanged } from 'room/lobby/redux'
import {
	handlePlayersChanged,
	handleQuestionAnswered,
	handleQuestionAsked,
	handleQuestionCompleted,
	handleQuizConcluded,
	handleReviewEtherPadClosed,
	handleReviewEtherPadGroupSwitched,
	handleReviewEtherPadOpened,
	handleReviewExcalidrawClosed,
	handleReviewExcalidrawGroupSwitched,
	handleReviewExcalidrawOpened,
	setGroupPartitionMap,
	setGroupsSuggestionAssignment,
	setReviewActiveGroup,
} from 'room/training-room/redux'
import { GroupsSuggestionAssignment } from 'room/training-room/set-groups/model/groups-suggestion-assignment'
import { assertUnreachable } from 'utils/unreachable'
import {
	setLatestInteractionSchemeEvent,
	setLatestWorkflowEvent,
} from './redux'

function getWorkflowEventSource(
	workflowId: WorkflowId,
	dispatch: Dispatch<any>,
	beeldayClient: BeeldayClient
) {
	return beeldayClient.workflowEventSource({
		workflowId: workflowId,
		onWorkflowEvent: event => dispatch(setLatestWorkflowEvent(event)),
	})
}

function getInteractionSchemeEventSource(
	address: InteractionSchemeAddress,
	dispatch: Dispatch<any>,
	beeldayClient: BeeldayClient,
	userId: UserId
) {
	return beeldayClient.interactionSchemeEventSource({
		workflowId: address.workflowId,
		interactionSchemeId: address.interactionSchemeId,
		onInitialStateEvents: event =>
			event.initialEvents.forEach(e => dispatch(events.toSSEAction(e))),
		onInteractionSchemeStateChange: event =>
			dispatch(setLatestInteractionSchemeEvent(event)),
		onInteractionSchemeRoomTransferWithCountdown: event =>
			dispatch(setISRoomTransferWithCountdown(event, userId)),
		onWorkflowEvent: event => dispatch(setLatestWorkflowEvent(event)),
	})
}

function getTrainingRoomEventSource(
	address: RoomAddress,
	dispatch: Dispatch<any>,
	beeldayClient: BeeldayClient,
	userId: UserId
) {
	return beeldayClient.trainingRoomEventSource({
		workflowId: address.workflowId,
		interactionSchemeId: address.interactionSchemeId,
		roomId: address.roomId,
		roomType: address.roomType,
		onInteractionSchemeStateChange: event =>
			dispatch(setLatestInteractionSchemeEvent(event)),
		onInteractionSchemeRoomTransferWithCountdown: event =>
			dispatch(setISRoomTransferWithCountdown(event, userId)),
		onWorkflowEvent: event => dispatch(setLatestWorkflowEvent(event)),
		onInitialStateEvent: event =>
			event.initialEvents.forEach(e => {
				switch (e.eventType) {
					case 'ACTIVE_GROUP_CHANGED':
						dispatch(setReviewActiveGroup(e.id))
						break
					case 'ETHER_PAD_OPENED':
						dispatch(handleReviewEtherPadOpened(e.groupId))
						break
					case 'EXCALIDRAW_OPENED':
						dispatch(handleReviewExcalidrawOpened(e.groupId))
						break
					case 'QUESTION_ASKED':
						dispatch(handleQuestionAsked(e))
						break
					case 'QUESTION_ANSWERED':
						dispatch(handleQuestionAnswered(e))
						break
					case 'QUESTION_COMPLETED':
						dispatch(handleQuestionCompleted(e))
						break
					case 'QUIZ_CONCLUDED':
						dispatch(handleQuizConcluded(e))
						break
					case 'ROOM_PHASE_CHANGED':
						dispatch(setTrainingRoomPhase(e.phase))
						break
					case 'GROUPS_SUGGESTION_STATE_CHANGED':
						dispatch(
							setGroupsSuggestionAssignment(
								new GroupsSuggestionAssignment(e.groups.map(toGroupSuggestions))
							)
						)
						break
					case 'GROUPS_PARTITION_MAP':
						dispatch(
							setGroupPartitionMap(
								e.partitionMap.sort((a, b) => a.maxGroupSize - b.maxGroupSize)
							)
						)
						break
					default:
						dispatch(events.toSSEAction(e))
				}
			}),
		onRoomStateChangedEvent: event => dispatch(setRoomUsers(event.state.users)),
		onGroupSuggestionStateChangedEvent: event =>
			dispatch(
				setGroupsSuggestionAssignment(
					new GroupsSuggestionAssignment(event.groups.map(toGroupSuggestions))
				)
			),
		onTrainingRoomGroupPartitionEvent: event =>
			dispatch(
				setGroupPartitionMap(
					event.partitionMap.sort((a, b) => a.maxGroupSize - b.maxGroupSize)
				)
			),
		onTrainingRoomPhaseChangedEvent: event =>
			dispatch(setTrainingRoomPhase(event.phase)),
		onReviewActiveGroupChangedEvent: event =>
			dispatch(setReviewActiveGroup(event.id)),
		onEtherPadOpened(event: EtherPadOpenedEvent) {
			dispatch(handleReviewEtherPadOpened(event.groupId))
		},
		onEtherPadClosed(event: EtherPadClosedEvent) {
			dispatch(handleReviewEtherPadClosed(event.groupId))
		},
		onEtherPadGroupSwitched(event) {
			dispatch(
				handleReviewEtherPadGroupSwitched(event.groupId, event.previousGroup)
			)
		},
		onExcalidrawOpened(event: ExcalidrawOpenedEvent) {
			dispatch(handleReviewExcalidrawOpened(event.groupId))
		},
		onExcalidrawClosed(event: ExcalidrawClosedEvent) {
			dispatch(handleReviewExcalidrawClosed(event.groupId))
		},
		onExcalidrawGroupSwitched(event: ExcalidrawGroupSwitchedEvent) {
			dispatch(
				handleReviewExcalidrawGroupSwitched(event.groupId, event.previousGroup)
			)
		},
		onQuestionAsked(event: QuizQuestionAsked) {
			dispatch(handleQuestionAsked(event))
		},
		onQuestionAnswered(event: QuizQuestionAnswered) {
			dispatch(handleQuestionAnswered(event))
		},
		onQuestionCompleted(event: QuizQuestionCompleted) {
			dispatch(handleQuestionCompleted(event))
		},
		onQuizConcluded(event: QuizConcludedEvent) {
			dispatch(handleQuizConcluded(event))
		},
		onPlayersChanged(event: PlayersChangedEvent) {
			dispatch(handlePlayersChanged(event))
		},
		onEvent(event: unknown) {
			dispatch(events.toSSEAction(event))
		},
	})
}

function getGroupRoomEventSource(
	address: RoomAddress,
	dispatch: Dispatch<any>,
	beeldayClient: BeeldayClient,
	userId: UserId
) {
	return beeldayClient.groupRoomEventSource({
		workflowId: address.workflowId,
		interactionSchemeId: address.interactionSchemeId,
		roomId: address.roomId,
		roomType: address.roomType,
		onEvent(event: any) {
			dispatch(events.toSSEAction(event))
		},
		onInteractionInitialState: event => {
			event.initialEvents.forEach(e => dispatch(events.toSSEAction(e)))
		},
		onInteractionSchemeStateChange: event =>
			dispatch(setLatestInteractionSchemeEvent(event)),
		onInteractionSchemeRoomTransferWithCountdown: event =>
			dispatch(setISRoomTransferWithCountdown(event, userId)),
		onWorkflowEvent: event => dispatch(setLatestWorkflowEvent(event)),
		onHandPositionChangedEvent(event: HandPositionChangedEvent) {
			dispatch(handleHandPositionChangedEvent(event))
		},
		onGroupStateChangedEvent(event: GroupStateChangedEvent) {
			dispatch(handleGroupStateChangedEvent(event))
		},
		onTrainerIsJoiningGroupEvent(event: TrainerIsJoiningGroupEvent) {
			dispatch(handleTrainerIsJoiningGroupEvent(event))
		},
		onTrainerStatusChangedEvent(event: TrainerStatusChangedEvent) {
			dispatch(handleTrainerStatusChangedEvent(event))
		},
		onTrainerBecameUnavailableEvent(event: TrainerBecameUnavailableEvent) {
			dispatch(handleTrainerBecameUnavailableEvent(event))
		},
		onRoomStateChangedEvent(event: RoomStateChangedEvent) {
			dispatch(setRoomUsers(event.state.users))
		},
		onTrainerBecameAvailableEvent(event: TrainerBecameAvailableEvent) {
			dispatch(handleTrainerBecameAvailableEvent(event))
		},
		onGroupPropertiesChanged(event: GroupPropertiesChangedEvent) {
			dispatch(changeGroupName(event.id, event.name))
		},
		onEtherPadOpened(event: EtherPadOpenedEvent) {
			dispatch(handleEtherPadOpened(event.groupId))
		},
		onEtherPadClosed(event: EtherPadClosedEvent) {
			dispatch(handleEtherPadClosed(event))
		},
		onExcalidrawOpened(event: ExcalidrawOpenedEvent) {
			dispatch(handleExcalidrawOpened(event.groupId))
		},
		onExcalidrawClosed(event: ExcalidrawClosedEvent) {
			dispatch(handleExcalidrawClosed(event.groupId))
		},
		onGroupsInitialStateEvent(event: GroupsInitialStateEvents) {
			event.initialEvents.forEach(e => {
				switch (e.eventType) {
					case 'HAND_POSITION_CHANGED':
						dispatch(handleHandPositionChangedEvent(e))
						break
					case 'TRAINER_STATUS_CHANGED':
						dispatch(handleTrainerStatusChangedEvent(e))
						break
					case 'ETHER_PAD_OPENED':
						dispatch(handleEtherPadOpened(e.groupId))
						break
					case 'EXCALIDRAW_OPENED':
						dispatch(handleExcalidrawOpened(e.groupId))
						break
					case 'GROUPS_STATE_CHANGED':
						dispatch(handleGroupStateChangedEvent(e))
						break
					case 'TRAINER_BECAME_UNAVAILABLE':
						dispatch(handleTrainerBecameUnavailableEvent(e))
						break
					default:
						dispatch(events.toSSEAction(e))
				}
			})
		},
	})
}

function getLobbyEventSource(
	address: RoomAddress,
	dispatch: Dispatch<any>,
	beeldayClient: BeeldayClient,
	userId: UserId
) {
	return beeldayClient.lobbyEventSource({
		workflowId: address.workflowId,
		interactionSchemeId: address.interactionSchemeId,
		roomId: address.roomId,
		roomType: address.roomType,
		onInteractionSchemeStateChange: event =>
			dispatch(setLatestInteractionSchemeEvent(event)),
		onInteractionSchemeRoomTransferWithCountdown: event =>
			dispatch(setISRoomTransferWithCountdown(event, userId)),
		onWorkflowEvent: event => dispatch(setLatestWorkflowEvent(event)),
		onInitialStateEvent: event =>
			event.initialEvents.forEach(e => dispatch(events.toSSEAction(e))),
		onRoomStateChangedEvent: event => {
			//HERE COMES CHANGES FROM LOBBY & PUBLIC CHAT ROOMS
			if (event.id === address.roomId) {
				dispatch(setRoomUsers(event.state.users))
			} else {
				dispatch(handlePublicChatRoomChanged(event.id, event.state.users))
			}
		},
		onEvent(event: any) {
			dispatch(events.toSSEAction(event))
		},
	})
}

function getPublicChatEventSource(
	address: RoomAddress,
	dispatch: Dispatch<any>,
	beeldayClient: BeeldayClient,
	userId: UserId
) {
	return beeldayClient.publicChatEventSource({
		workflowId: address.workflowId,
		interactionSchemeId: address.interactionSchemeId,
		roomId: address.roomId,
		roomType: address.roomType,
		onInteractionSchemeStateChange: event =>
			dispatch(setLatestInteractionSchemeEvent(event)),
		onInteractionSchemeRoomTransferWithCountdown: event =>
			dispatch(setISRoomTransferWithCountdown(event, userId)),
		onWorkflowEvent: event => dispatch(setLatestWorkflowEvent(event)),
		onRoomStateChangedEvent: event => {
			//HERE COME CHANGES FROM CURRENT ROOM & OTHER PUBLIC CHAT ROOMS
			if (event.id === address.roomId) {
				dispatch(setRoomUsers(event.state.users))
			}

			dispatch(handlePublicChatRoomChanged(event.id, event.state.users))
		},
		onEvent(event) {
			dispatch(events.toSSEAction(event))
		},
		onInitialStateEvent(event) {
			event.initialEvents.forEach(e => dispatch(events.toSSEAction(e)))
		},
	})
}

function getRoomEventSource(
	address: RoomAddress,
	dispatch: Dispatch<any>,
	roomType: RoomType,
	beeldayClient: BeeldayClient,
	userId: UserId
) {
	switch (roomType) {
		case RoomType.TrainingRoom:
			return getTrainingRoomEventSource(
				address,
				dispatch,
				beeldayClient,
				userId
			)
		case RoomType.WebinarRoom:
			return getTrainingRoomEventSource(
				address,
				dispatch,
				beeldayClient,
				userId
			)
		case RoomType.GroupRoom:
			return getGroupRoomEventSource(address, dispatch, beeldayClient, userId)
		case RoomType.Lobby:
			return getLobbyEventSource(address, dispatch, beeldayClient, userId)
		case RoomType.PublicChat:
			return getPublicChatEventSource(address, dispatch, beeldayClient, userId)
		default:
			assertUnreachable('RoomType', roomType)
	}
}

export const EventSourceManager: FunctionComponent = () => {
	const eventsSourcePath = useSelector(
		(state: RootState) => state.eventSource.eventsSourcePath
	)
	const workflow = useSelector((state: RootState) => state.workflow)

	const interactionScheme = useSelector(
		(state: RootState) => state.interactionScheme
	)

	const dispatch = useDispatch()
	const beeldayClient = useAuthenticatedBeeldayClient()
	const user = security.useAuthenticatedUser()

	const eventSourceRef = useRef<EventSource | undefined>()
	const timeoutRef = useRef<NodeJS.Timer | undefined>()

	const handleEventSourceClose = () => {
		eventSourceRef.current?.close()

		connectToEventSource()
	}
	const connectToEventSource = useCallback(() => {
		logger.debug('START CONNECTING TO EVENT SOURCE')
		switch (eventsSourcePath) {
			case EventsSourcePath.room: {
				if (
					!workflow.workflowId ||
					!workflow.joinedInteractionScheme ||
					!interactionScheme.joinedRoom ||
					!user
				) {
					return
				}
				const address: RoomAddress = {
					workflowId: workflow.workflowId,
					...workflow.joinedInteractionScheme,
					...interactionScheme.joinedRoom,
				}

				logger.debug('CONNECTING TO EVENT SOURCE ROOM')
				eventSourceRef.current = getRoomEventSource(
					address,
					dispatch,
					interactionScheme.joinedRoom?.roomType,
					beeldayClient,
					user.id
				)

				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current)
					timeoutRef.current = undefined
				}

				break
			}
			case EventsSourcePath.interactionScheme: {
				if (
					!workflow.workflowId ||
					!workflow.joinedInteractionScheme ||
					!user
				) {
					return
				}
				const address: InteractionSchemeAddress = {
					workflowId: workflow.workflowId,
					...workflow.joinedInteractionScheme,
				}

				logger.debug('CONNECTING TO EVENT SOURCE INTERACTION SCHEME')
				eventSourceRef.current = getInteractionSchemeEventSource(
					address,
					dispatch,
					beeldayClient,
					user.id
				)

				if (timeoutRef.current) {
					clearTimeout(timeoutRef.current)
					timeoutRef.current = undefined
				}
				break
			}
			case EventsSourcePath.workflow: {
				if (!workflow.workflowId) {
					return
				}
				logger.debug('CONNECTING TO EVENT SOURCE WORKFLOW')
				eventSourceRef.current = getWorkflowEventSource(
					workflow.workflowId,
					dispatch,
					beeldayClient
				)
				logger.debug('INITIALIZE EVENT SOURCE TIMEOUT')
				timeoutRef.current = setTimeout(() => {
					logger.debug('TRIGGER EVENT SOURCE TIMEOUT')
					if (eventSourceRef.current) {
						eventSourceRef.current.close()
					}
					if (timeoutRef.current) {
						clearTimeout(timeoutRef.current)
						timeoutRef.current = undefined
					}
					if (workflow.workflowId) {
						beeldayClient
							.joinWorkflow({ workflowId: workflow.workflowId })
							.then(() => connectToEventSource())
					}
				}, 7000)

				break
			}
		}
	}, [
		beeldayClient,
		dispatch,
		eventsSourcePath,
		interactionScheme.joinedRoom,
		user,
		workflow.joinedInteractionScheme,
		workflow.workflowId,
	])

	useHandleServerBeat(eventSourceRef.current, handleEventSourceClose)

	useEffect(() => {
		connectToEventSource()

		return () => {
			if (eventSourceRef.current) {
				eventSourceRef.current.close()
			}
			if (timeoutRef.current) {
				logger.debug('CLEAR EVENT SOURCE TIMEOUT')
				clearTimeout(timeoutRef.current)
				timeoutRef.current = undefined
			}
		}
	}, [
		beeldayClient,
		connectToEventSource,
		dispatch,
		eventsSourcePath,
		interactionScheme.joinedRoom,
		user,
		workflow.joinedInteractionScheme,
		workflow.workflowId,
	])

	return <></>
}

const useHandleServerBeat = (
	eventSource: EventSource | undefined,
	handleEventSourceClose: () => void
) => {
	const timeoutRef = useRef<NodeJS.Timer | undefined>(undefined)

	useEffect(() => {
		const handleServerBeatEvent = () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
				timeoutRef.current = undefined
			}

			if (!timeoutRef.current) {
				timeoutRef.current = setTimeout(() => {
					if (timeoutRef.current) {
						clearTimeout(timeoutRef.current)
					}
					eventSource?.removeEventListener('SERVER_BEAT', handleServerBeatEvent)
					handleEventSourceClose()
				}, Config.eventSourceCloseTimeout)
			}
		}

		if (eventSource) {
			eventSource.addEventListener('SERVER_BEAT', handleServerBeatEvent)
		}

		return () => {
			if (timeoutRef.current) {
				clearTimeout(timeoutRef.current)
			}

			if (eventSource) {
				eventSource.removeEventListener('SERVER_BEAT', handleServerBeatEvent)
			}
		}
	}, [eventSource, handleEventSourceClose])

	return eventSource
}
