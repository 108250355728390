import { intl, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { RoomAddress } from 'common/types'
import { useCallback } from 'react'
import { useProfilesFor } from 'users/redux'
import useFetchMissingProfiles from 'users/use-fetch-missing-profiles'
import { useQuestions } from '.'
import VotingResult from './voting-result'
import DraggableContent from './draggable-content'
import {
	Header,
	HeaderTitle,
	Fill,
	Body,
	Inner,
	HeaderSubtitle,
	TrainerFace,
} from './ui'
import { useWorkflowUser } from 'room/common/use-workflow-user'
import { useSelector } from 'react-redux'
import { RootState } from 'common/redux'

const Buttons = styled.div`
	column-gap: 16px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-top: 25px;

	${ui.responsive.desktop1440} {
		margin-top: 30px;
	}
`

type Props = {
	roomAddress: RoomAddress
	totalNumberOfUsers: number
}

const VotingResultsModal = ({
	roomAddress,
	totalNumberOfUsers,
}: Props): JSX.Element => {
	const questions = useQuestions()
	const { result } = questions

	const trainer = useWorkflowUser()
	const trainerProfile = useSelector(
		(state: RootState) => state.users.all[trainer?.id ? trainer.id : '']
	)

	const startNextVoting = useCallback(() => {
		questions.startVoting(roomAddress)
	}, [roomAddress, questions])

	const finishVoting = useCallback(() => {
		questions.finishVoting(roomAddress)
	}, [roomAddress, questions])

	useFetchMissingProfiles(result?.voted || [])

	const profiles = useProfilesFor(result?.voted || [])

	return (
		<DraggableContent
			header={
				<Header>
					<TrainerFace className="trainerFace" profile={trainerProfile} />
					<Fill>
						<HeaderTitle>
							<intl.Translate>features.question.ask</intl.Translate>
						</HeaderTitle>
						<HeaderSubtitle>
							<intl.Translate>features.question.ask_read</intl.Translate>
						</HeaderSubtitle>
					</Fill>
					<ui.ButtonClose onClick={finishVoting} />
				</Header>
			}
			body={
				<Body>
					<Inner>
						<VotingResult
							yes={result?.yes || 0}
							total={totalNumberOfUsers}
							voted={result?.voted || []}
							profiles={profiles}
						/>
						<Buttons>
							<ui.ButtonMinor onClick={finishVoting}>
								<intl.Translate>features.question.cancel</intl.Translate>
							</ui.ButtonMinor>
							<ui.ButtonPrimary onClick={startNextVoting}>
								<intl.Translate>features.question.next_voting</intl.Translate>
							</ui.ButtonPrimary>
						</Buttons>
					</Inner>
				</Body>
			}
		/>
	)
}

export default VotingResultsModal
