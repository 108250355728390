import { colors, icons, intl, UserProfile, ui } from '@beelday/common'
import styled from '@emotion/styled'
import { PROFILE_MISSING } from 'common/types'
import ProfilesInRow from 'users/profiles-in-row'
import thumbDown from './thumb_down.png'
import thumbUp from './thumb_up.png'

const Voted = styled.div`
	align-items: center;
	display: flex;
`

const Chart = styled.div`
	border: solid 1px ${colors.brightGray};
	border-radius: 8px;
	height: 65px;
	margin-top: 12px;
	overflow: hidden;
	position: relative;

	${ui.responsive.desktop1440} {
		height: 80px;
	}
`

const Inner = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
`

const Bar = styled.div<{ width: number }>`
	align-items: center;
	color: ${colors.black};
	display: flex;
	font-size: 24px;
	height: 80px;
	justify-content: center;
	transition: width 0.5s;
	width: ${props => props.width}%;
`

const Yes = styled(Bar)`
	background-color: ${colors.lightGreen};
`

const No = styled(Bar)`
	background-color: ${colors.lightPink};
`

const Percentage = styled.div`
	align-items: center;
	display: flex;
	justify-content: center;
	min-width: 80px;
	width: 50%;

	img {
		margin-right: 10px;
		width: 32px;
	}
`

const EmptyContainer = styled.div`
	align-items: center;
	bottom: 0;
	color: ${colors.heather};
	display: flex;
	font-size: 15px;
	justify-content: center;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
`

type Props = {
	total: number
	voted: string[]
	profiles: (UserProfile | PROFILE_MISSING)[]
	yes: number
}

const Empty = () => (
	<EmptyContainer>
		<span style={{ marginRight: '5px' }}>
			<icons.Loader width="20px" />
		</span>
		<intl.Translate>features.question.no_one_answered</intl.Translate>
	</EmptyContainer>
)

const VotingResult = ({ total, voted, yes, profiles }: Props): JSX.Element => {
	const noOneVoted = voted.length === 0
	const yesPercent = Math.ceil((yes / voted.length) * 100)
	const noPercent = 100 - yesPercent

	return (
		<div>
			<Voted>
				<span>
					{voted.length}/{total}&nbsp;
				</span>
				<intl.Translate>features.question.answered</intl.Translate>
				<ProfilesInRow profiles={profiles} />
			</Voted>
			<Chart>
				{noOneVoted ? (
					<Empty />
				) : (
					<>
						<Inner>
							<Yes width={yesPercent} />
							<No width={noPercent} />
						</Inner>
						<Inner>
							<Percentage>
								<img src={thumbUp} />
								<span>{yesPercent}%</span>
							</Percentage>
							<Percentage>
								<img src={thumbDown} />
								<span>{noPercent}%</span>
							</Percentage>
						</Inner>
					</>
				)}
			</Chart>
		</div>
	)
}

export default VotingResult
